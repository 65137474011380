<template>
  <div class="cell">
    <transition name="fade">
      <settings-warning v-if="hasChanges" style="animation-duration: 0.5s" />
    </transition>
    <hcc-table
      :title="$t('configuration.schedules.title')"
      :initialPage="1"
      :columns="columns"
      :isSchedule="true"
      :rows="rows"
      @edit="edit"
      @delete="deleteItem"
      :pagination="false"
    >
      <template slot="table-no-actions">
        <hcc-select
          :label="$t('configuration.schedules.time-zone')"
          custom-class="select"
          v-model="scheduleTimezone"
          :options="timezoneList"
          optionLabel=""
        />
      </template>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'is24'">
          <hcc-toggle-switch
            size="sm"
            :value="props.row.is24"
            :name="'toggle' + props.row.day"
            @change="set24($event, props.row)"
          />
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </hcc-table>
    <div class="cell flex-container align-right save-buttons">
      <hcc-button
        color="muted"
        :disabled="!hasChanges"
        @click="undoChanges"
        class="save-buttons__button"
        >{{ $t("configuration.delete") }}
      </hcc-button>
      <hcc-button :disabled="!hasChanges" @click="handleConfirm"
        >{{ $t("channels-settings.save") }}
      </hcc-button>
    </div>
    <transition name="fade">
      <edit-schedule :schedules="schedule" @save="save" @cancel="cancel" />
    </transition>
    <transition name="fade">
      <hcc-confirmation v-if="deleteDay" />
      <hcc-confirmation :confirmFunction="undoChanges" v-else />
    </transition>
    <transition name="fade">
      <error-modal :message="error" />
    </transition>
  </div>
</template>

<script>
import EventBus from '@/eventBus';
import {
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_ERROR,
  UPDATE_CAMPAIGN_DONE,
} from '@/eventTypes';
import ErrorHandler from '@/utils/error.handler';
import SettingsWarning from './SettingsWarning.vue';


export default {
  components: {
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
    EditSchedule: () => import('@/components/settings/EditSchedule.vue'),
    ErrorModal: () => import('@/components/ErrorModal.vue'),
    HccToggleSwitch: () => import('@/components/shared/HccToggleSwitch/index.vue'),
    HccSelect: () => import('@/components/shared/HccSelect/index.vue'),
    HccButton: () => import('@/components/shared/HccButton/index.vue'),
    SettingsWarning,
  },
  props: {
    campaign: {
      required: true,
    },
  },
  data() {
    return {
      error: '',
      schedules: [],
      defaultTimeZone: 'America/Mexico_City',
      scheduleTimezone: this.campaign.timezone,
      timezoneList: this.$moment.tz.names(),
      columns: [],
      rows: [],
      schedule: {},
      empty: '-------------------',
      originalSchedules: [],
      changes: false,
      deleteDay: false,
      milliseconds24: 86340000,
    };
  },
  watch: {
    campaign(newVal) {
      if (newVal && newVal.schedules) {
        this.schedules = newVal.schedules;
        this.createRows();
      }
    },
    scheduleTimezone(timezone) {
      const data = {
        id: this.campaign.id,
        timezone,
      };
      EventBus.$emit(UPDATE_CAMPAIGN, { data, tab: 'Schedule' });
    },
  },
  created() {
    EventBus.$on(UPDATE_CAMPAIGN_DONE, (tab) => {
      if (tab === 'Schedule') {
        this.changes = false;
        this.$toasted.global.success(this.$t('configuration.schedules.updated'));
      }
    });
    EventBus.$on(UPDATE_CAMPAIGN_ERROR, (err) => {
      ErrorHandler.logErrors(err);
    });
  },
  mounted() {
    this.schedules = this.campaign.schedules;
    this.originalSchedules = this.campaign.schedules;
    this.createRows();
  },
  destroyed() {
    this.changes = false;
    EventBus.$off(UPDATE_CAMPAIGN_DONE);
    EventBus.$off(UPDATE_CAMPAIGN_ERROR);
  },
  computed: {
    hasChanges() {
      const changes = this.changes
          && JSON.stringify(this.originalSchedules) !== JSON.stringify(this.schedules);

      this.$emit('changed', changes);

      return changes;
    },
  },
  methods: {
    createRows() {
      const weekSchedule = this.orderSchedulesByDay();
      let colNumber = 1;

      this.rows = weekSchedule.map(({ day, values }) => {
        const is24 = values.length === 1 ? this.validateIf24h(values) : false;
        if (values.length > colNumber) colNumber = values.length;


        const columns = this.formatFn(values);

        return {
          is24,
          day,
          values,
          ...columns,
        };
      });
      this.buildColumns(colNumber);
    },
    setDay(day) {
      const weekDays = [
        this.$t('configuration.schedules.mon'),
        this.$t('configuration.schedules.tues'),
        this.$t('configuration.schedules.wed'),
        this.$t('configuration.schedules.thurs'),
        this.$t('configuration.schedules.fri'),
        this.$t('configuration.schedules.sat'),
        this.$t('configuration.schedules.sun'),
      ];
      return weekDays[day - 1];
    },
    edit(props) {
      this.schedule = { ...props, name: this.setDay(props.day) };
      this.$modal.show('edit-modal');
    },
    buildColumns(num) {
      this.columns = [{
        label: this.$t('configuration.schedules.day'),
        field: 'day',
        formatFn: this.setDay,
      }];
      for (let i = 0; i < num; i += 1) {
        this.columns.push({
          label: this.$t('configuration.schedules.range'),
          field: `column${i}`,
        });
      }
      this.columns.push({
        label: '24H',
        field: 'is24',
        sortable: false,
      });
    },
    deleteItem(props) {
      if (props.values.length !== 0) {
        this.deleteDay = true;
        this.$modal.show('confirmation', {
          title: this.$t('configuration.schedules.delete', { day: this.setDay(props.day) }),
          description: this.$t('configuration.confirmation'),
          variant: 'error',
          confirm: () => this.setSchedulesData([], props.day),
        });
      } else {
        this.$toasted.global.error(this.$t('configuration.schedules.empty-day'));
      }
    },
    setSchedulesData(schedules, day) {
      this.changes = true;
      if (this.schedules) {
        this.schedules = this.schedules.filter(schedule => schedule.weekday !== day);
      } else {
        this.schedules = [];
      }

      if (schedules.length !== 0) {
        schedules.forEach(((schedule) => {
          // eslint-disable-next-line no-param-reassign
          schedule.start = this.getTime(schedule.start).toString();
          // eslint-disable-next-line no-param-reassign
          schedule.end = this.getTime(schedule.end).toString();
        }));

        this.schedules = this.schedules.concat(schedules);
      }

      this.createRows();
      this.deleteDay = false;
    },
    saveSchedules(props) {
      const data = {
        id: this.campaign.id,
        schedules: props,
      };

      EventBus.$emit(UPDATE_CAMPAIGN, { data, tab: 'Schedule' });
    },
    set24(event, props) {
      if (event) {
        const sch = {
          start: new Date('2021-01-01 00:00'),
          end: new Date('2021-01-01 23:59'),
          weekday: props.day,
        };
        this.setSchedulesData([sch], props.day);
      } else {
        const sch = props;
        sch.is24 = event;
        this.$set(this.rows, (props.day - 1), sch);
        this.edit(props);
      }
    },
    validateIf24h([{ start, end }]) {
      return Math.abs(start - end) === this.milliseconds24;
    },
    orderSchedulesByDay() {
      const sch = [];
      for (let i = 1; i < 8; i += 1) {
        const list = this.schedules.filter(({ weekday }) => weekday === i);
        sch.push({ values: list, day: i });
      }
      return sch;
    },
    setTime(time) {
      const timezone = this.scheduleTimezone ?? this.defaultTimeZone;
      return this.$moment(parseInt(time, 10)).tz(timezone).format('HH:mm');
    },
    filterToNewScheduleValue(day) {
      return this.schedules.filter(sch => sch.weekday !== day)
        .map(({ start, end, weekday }) => ({ start, end, weekday }));
    },
    save(data) {
      const { error, list, day } = data;
      if (error) {
        this.error = this.$t('configuration.schedules.error');
        this.$toasted.global.error(this.error);
      } else if (day !== 'all') {
        this.setSchedulesData(list, day);
      } else {
        this.replicateSchedule(list);
      }
      this.schedule = {};
    },
    cancel(props) {
      if (props) {
        const sch = props;
        if (sch.values.length === 1) {
          sch.is24 = this.validateIf24h(sch.values);
        }
        this.$set(this.rows, (props.day - 1), sch);
      }
      this.schedule = {};
    },
    formatFn(values) {
      let columns = {};
      [0, 1, 2].forEach((i) => {
        const val = values[i]
          ? `${this.setTime(values[i].start)} - ${this.setTime(values[i].end)}`
          : this.empty;
        columns = {
          ...columns,
          [`column${i}`]: val,
        };
      });
      return columns;
    },
    handleConfirm() {
      const sch = this.schedules.map(({ __typename, ...attrs }) => attrs);

      this.saveSchedules(sch);
    },
    undoChanges() {
      this.changes = false;
      this.schedules = this.campaign.schedules;
      this.createRows();
    },
    getTime(time) {
      return Date.parse(time);
    },
    replicateSchedule(list) {
      this.changes = true;
      const schedules = [];
      for (let i = 1; i < 8; i += 1) {
        list.forEach((sch) => {
          const start = this.getTime(sch.start).toString();
          const end = this.getTime(sch.end).toString();
          schedules.push({ start, end, weekday: i });
        });
      }
      this.schedules = schedules;
      this.createRows();
      this.deleteDay = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/settings/shared/_modal.scss";
@import "~styles/components/settings/_schedule-tab.scss";
</style>
